import { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import TreePopup from '../../components/TreePopup';
import MicroclimateValidation from './MicroclimateValidation';
import { isTreeDone } from './isTreeDone';
import { exportCsv } from './csvExport';
import useApi from '../../hooks/api';
import TreeSelector from '../../components/HeaderBar/TreeSelector';
import { Provider as SavedMicroclimateValuesProvider } from '../../providers/savedMicroclimateValues';
import { BetaManagedAreasContextProvider, useBetaManagedAreasContext } from '../../hooks/betaHooks/beta-managed-areas-context';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

//! Important:
//! We will need to delete Completed status from this list when the full flow is implemented
const MICROCLIMATE_TODO_TREE_STATUSES = [
  TreeFlowStatus.SpeciesValidationQueued,
  TreeFlowStatus.SpeciesValidationRunning,
  TreeFlowStatus.SpeciesValidationDone,
  TreeFlowStatus.Completed, // for a while we will display completed trees as well
];

const layerFilter = [
  {
    id: 'validation_todo',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SpeciesValidationQueued, TreeFlowStatus.SpeciesValidationRunning],
  },
  {
    id: 'validation_done',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SpeciesValidationDone, TreeFlowStatus.Completed],
  },
];

const generateTableColumns = (exportCsvCallback, sortTrees, jumpToTree) => (selection) => {
  return selection
    ? [
        {
          title: 'Tree ID',
          key: 'id',
          resolver: 'id',
          sortable: true,
          format: 'ESS_ID',
          onSort: sortTrees,
          clickNavigation: (entry) => jumpToTree(entry),
        },
        {
          title: 'Genus and Species',
          resolver: (t) => `${t.genus_name ?? ''} ${t.species_name ?? ''}`,
          key: 'genus_and_species',
          sortable: true,
          onSort: sortTrees,
        },
        {
          title: 'Genus Confidence',
          resolver: (t) => t.genus_confidence,
          key: 'genus_confidence',
          sortable: true,
          onSort: sortTrees,
          format: 'CONFIDENCE',
        },
        {
          title: 'Species Confidence',
          resolver: (t) => t.species_confidence,
          key: 'species_confidence',
          sortable: true,
          onSort: sortTrees,
          format: 'CONFIDENCE',
        },
        {
          title: 'Crown light exposure',
          resolver: (t) => t.microclimate?.crown_light_exposure ?? '-',
          key: 'crown_light_exposure',
          sortable: true,
          onSort: sortTrees,
        },
        {
          title: 'Status',
          key: 'status',
          resolver: (t) => (isTreeDone(t) ? 'Done' : 'To Do'),
          sortable: true,
          format: 'ESS_STATUS',
          onSort: sortTrees,
        },
        {
          title: 'Completed',
          key: 'completed',
          resolver: (t) => t.microclimate.completed ?? false,
          sortable: true,
          format: 'COMPLETED',
          onSort: sortTrees,
        },
        {
          title: 'Uncertain',
          key: 'uncertain',
          resolver: (t) => t.microclimate.uncertain ?? false,
          sortable: true,
          format: 'UNCERTAIN',
          onSort: sortTrees,
        },
      ]
    : [
        {
          title: 'Area code',
          key: 'code',
          resolver: 'code',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'OIC',
          key: 'officer_in_charge',
          resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
          sortable: true,
        },
        {
          title: 'Number of trees',
          key: 'microclimate_params_trees_all',
          resolver: 'microclimate_params_trees_all',
          sortable: true,
        },
        {
          title: 'Todo',
          key: 'microclimate_params_trees_todo',
          resolver: 'microclimate_params_trees_todo',
          sortable: true,
        },
        {
          title: 'Done',
          key: 'microclimate_params_trees_ok',
          resolver: 'microclimate_params_trees_ok',
          sortable: true,
        },
        {
          title: '%',
          key: 'progress',
          format: 'PERCENTAGE',
          resolver: (ma) => ma.microclimate_params_trees_ok / ma.microclimate_params_trees_all || 0,
          sortable: true,
        },
        {
          title: 'Status',
          key: 'status',
          resolver: (ma) => (ma.microclimate_params_trees_ok === ma.microclimate_params_trees_all ? '✅' : 'TODO'),
          format: 'STATUS',
          sortable: true,
        },
        {
          title: 'Download CSV',
          subTitle: 'Dowload all CSVs',
          subTitleAction: generateAllReadyCSVs(exportCsvCallback),
          key: 'download',
          resolver: generateDownloadButton(exportCsvCallback),
          format: 'STATUS',
        },
      ];
};

const generateAllReadyCSVs = (exportCsvCallback) => (mas) => {
  const allReadyToExportMas = mas.filter(
    (ma) => ma.semantic_validation === 'done' && ma.microclimate_params_trees_ok === ma.microclimate_params_trees_all
  );

  exportCsvCallback(allReadyToExportMas);
};
const generateDownloadButton = (exportCsvCallback) => (ma) => {
  return (
    <div
      style={{ cursor: 'copy' }}
      onClick={(event) => {
        event.stopPropagation();
        exportCsvCallback([ma]);
      }}
    >
      💾
    </div>
  );
};

const MicroclimateInputComponent = ({ location, history, match }) => {
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;
  const mapRef = useRef(null);
  const { handleRequest } = useApi();

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    betaMASCtx.reset();
    betaMASCtx.fetch();
  }, [managedAreaId]);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;

    betaMACtx.fetch();
  }, [managedAreaId]);

  const [activeTabs, setActiveTabs] = useState({
    left: 'pointcloud',
    right: 'panoramic',
  });

  const managedArea = betaMACtx?.managedArea;
  const trees = betaMACtx.trees;

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea]);

  const todoTreesFilter = (t) => t.tree_flow_status && MICROCLIMATE_TODO_TREE_STATUSES.includes(t.tree_flow_status);

  const generateNavigationTabs = useCallback((managedAreaId, managedAreaCode, treeId, onChangeTree) => {
    return [
      {
        type: 'link',
        path: `/microclimate-input`,
        active: true,
        onClick: () => history.push(`/microclimate-input`),
        icon: 'map',
        titleOnHover: 'Jump to the Organization',
      },
      {
        type: 'link',
        active: !!managedAreaId && !!managedAreaCode,
        onClick: () => history.push(`/microclimate-input/${managedAreaId}`),
        title: managedAreaCode,
        titleOnHover: 'Jump to the Managed Area',
      },
      {
        type: 'component',
        active: !!treeId,
        params: {
          treeId: treeId,
          onChangeTree,
        },
        component: TreeSelector,
      },
    ];
  }, [history]);

  const layerFilters = useMemo(() => {
    return layerFilter.map((layer) => ({
      ...layer,
    }));
  }, []);

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      const clickedTree = trees?.find((tree) => Number(tree.id) === Number(feature.id));
      const isMavenTree = feature.properties.status === undefined;

      let treeData;
      if (isMavenTree) {
        treeData = feature;
      } else {
        treeData = {
          ...feature,
          properties: {
            ...feature.properties,
            manual_scientific_name: clickedTree?.manual_scientific_name,
            maven_tree_id: clickedTree?.tree_id,
            spsc_nm: clickedTree?.spsc_nm,
          },
        };
      }

      return <TreePopup tree={treeData} {...props} />;
    },
    [trees]
  );

  const exportCsvCallback = useCallback(
    (managedAreas) => {
      exportCsv(managedAreas, handleRequest);
    },
    [handleRequest]
  );

  const jumpToTree = (tree) => {
    history.push(window.location.pathname.split('/').slice(0, 4).join('/') + '/validation/' + tree.id);
  };

  const tableColumns = generateTableColumns(exportCsvCallback, trees.sortTreesByDirection, jumpToTree);

  return (
    <SavedMicroclimateValuesProvider>
      <SkeletonWithManagedAreaMap
        basePath='microclimate-input'
        screens={[
          {
            title: `${managedArea?.code} | Microclimate Validation`,
            path: '/microclimate-input/:MA/validation/:tree?',
            Component: MicroclimateValidation,
          },
        ]}
        title='Microclimate input'
        mapRef={mapRef}
        layerFilter={layerFilters}
        treePopup={treePopup}
        customColumns={tableColumns}
        selection={selection}
        contextsLoadingState={contextsLoadingState}
        activeTabs={activeTabs}
        setActiveTabs={setActiveTabs}
        isMicroClimate={true}
        todoTreesFilter={todoTreesFilter}
        generateNavigationTabs={generateNavigationTabs}
      />
    </SavedMicroclimateValuesProvider>
  );
};

const MicroclimateInput = (props) => {
  const managedAreaId = props.match.params?.MA ? parseInt(props.match.params?.MA) : null;

  return (
    <BetaManagedAreasContextProvider isMicroclimate={true}>
      <BetaManagedAreaContextProvider managedAreaId={managedAreaId} isMicroclimate={true}>
        <MicroclimateInputComponent {...props} />
      </BetaManagedAreaContextProvider>
    </BetaManagedAreasContextProvider>
  );
};

export default MicroclimateInput;
