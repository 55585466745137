import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Skeleton, Route } from '.';
import ManagedAreaMap from './MASelectorMap';
import { useBetaManagedAreaContext } from '../hooks/betaHooks/beta-managed-area-context';
import { useBetaManagedAreasContext } from '../hooks/betaHooks/beta-managed-areas-context';
import getHeaderTitle from '../utils/getHeaderTitle';
import useBetaTableSorter from '../hooks/betaHooks/beta-use-table-sorter';
import TreeFlowStatus from '../enums/TreeFlowStatus';
import { useBetaTreeIndex } from '../hooks/betaHooks/beta-use-tree-index';

const SkeletonWithManagedAreaMap = ({
  basePath,
  screens = [],
  title,
  mapRef,
  layerFilter,
  layerSources = [
    { id: 'trees', source: 'trees', name: 'Trees' },
    { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
  ],
  sliders,
  treePopup,
  treesFilter,
  controls = [],
  tableHeader,
  customColumns = null,
  treeStatusFilter,
  maStatusFilter,
  screenType,
  selection,
  contextsLoadingState,
  activeTabs,
  setActiveTabs,
  setOfficerInCharge,
  isMicroClimate,
  todoTreesFilter,
  generateNavigationTabs,
}) => {
  const history = useHistory();
  const urlMatch = useRouteMatch("/:viewType/:ma?/:validationType?/:tree?");

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  const pipelines = betaMASCtx.filteredPipelines;
  const pipeline = betaMACtx.pipeline;
  const managedAreas = betaMASCtx.filteredManagedAreas;
  const managedArea = betaMACtx.managedArea;
  const trees = treesFilter ? betaMACtx.trees.filter(treesFilter) : betaMACtx.trees;

  const {
    sortedData,
    handleTableSortChange,
    handleTableConfigChange,
  } = useBetaTableSorter({ data: managedArea?.id ? trees : pipelines });
  const sortedPipelines = useMemo(() => !managedArea?.id ? sortedData : [], [managedArea?.id, sortedData]);
  const sortedTrees = useMemo(() => managedArea?.id ? sortedData : [], [managedArea?.id, sortedData]);

  const [selectedTree, setSelectedTree] = useState(null);
  const selectedTreeId = selectedTree?.id ?? null;
  const currentTreeId = urlMatch.params.tree ?? null;

  useEffect(() => {
    betaMASCtx.filterPipelinesByStatus(treeStatusFilter, maStatusFilter);
  }, [treeStatusFilter, maStatusFilter])

  const [proximityAlertTreeIDs, setProximityAlertTreeIDs] = useState([]);
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const [deletedTrees, setDeletedTrees] = useState([]);

  const _handleMAFocus = () => {
    const ps = sortedPipelines?.length ? sortedPipelines : pipelines;
    const bbox = ps.map((pipeline) => pipeline?.bbox && JSON.parse(pipeline?.bbox).coordinates);
    if (bbox && bbox.length > 1) {
      mapRef.current.focusOnBBox(bbox);
    }
  };

  const _handleMapFocus = () => {
    if (!mapRef.current) return;
    if (managedArea?.id) mapRef.current.focusOnBBox(managedArea?.frontend_aoi_bbox?.coordinates);
    else _handleMAFocus();
  };

  useEffect(() => {
    _handleMapFocus();
  }, [pipelines?.length, managedArea?.id]);

  const todoTrees = useMemo(() => {
    const treesToFilter = sortedTrees?.length ? sortedTrees : trees;
    return treesToFilter?.filter(todoTreesFilter) ?? [];
  }, [trees, sortedTrees, todoTreesFilter]);

  const { selectTree, moveToNeighbour } = useBetaTreeIndex({
    trees: todoTrees,
    currentTreeId: currentTreeId,
    todoStatuses: [TreeFlowStatus.LocationValidationQueued],
    skippedTrees: JSON.parse(localStorage.getItem('skippedTrees') || '[]'),
    setTree: (tree) => {
      setSelectedTree(tree);
      if (basePath === "validation") {
        const validationType = urlMatch.params.validationType;
        history.push(`/validation/${managedArea.id}/${validationType}/${tree.id}`);
      }
      else if (basePath === "microclimate-input") {
        history.push(
          `/microclimate-input/${managedArea.id}/validation/${tree.id}?left=${activeTabs.left}&right=${activeTabs.right}`
        );
      }
    },
  });

  useEffect(() => {
    const storedManagedAreaId = localStorage.getItem('managedAreaId');
    if (storedManagedAreaId && managedArea?.id && storedManagedAreaId.toString() !== managedArea.id.toString()) {
      localStorage.removeItem('skippedTrees');
    }
  }, [managedArea?.id]);

  const addSkippedTree = useCallback((treeId) => {
    const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
    if (skippedTrees.indexOf(treeId) === -1) {
      skippedTrees.push(treeId);

      if (skippedTrees.length < todoTrees.length) {
        localStorage.setItem('skippedTrees', JSON.stringify(skippedTrees));
      }
      else {
        localStorage.removeItem('skippedTrees');
      }
    }
  }, [todoTrees.length]);

  const removeSkippedTree = useCallback((treeId) => {
    const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
    const index = skippedTrees.indexOf(treeId);
    if (index !== -1) {
      skippedTrees.splice(index, 1);
      localStorage.setItem('skippedTrees', JSON.stringify(skippedTrees));
    }
  }, []);

  const handleChangeTree = (direction) => {
    if (!isMicroClimate) {
      // Currently on the microclimate view all trees are used for navigation, not just 
      // the todo trees, so the skipped tree feature will not work properly. You would 
      // have to navigate through all trees (even completed ones) to get to the 
      // previously  skipped trees again.
      if (direction === 1) {
        addSkippedTree(currentTreeId);
      } else {
        removeSkippedTree(currentTreeId);
      }
    }
    moveToNeighbour(direction);
  }

  const steps = {
    location_validation: 'location',
    semantic_validation: 'semantics',
    segmentation_validation: 'segmentation',
  };

  const isActionAvailable = todoTrees?.length > 0 && isMicroClimate ? (
    !!pipeline && pipeline?.microclimate_params_trees_todo > 0
  ) : (
    pipeline &&
    Object.keys(steps).includes(pipeline?.current_manual_step) &&
    !(
      pipeline.location_proposal === 'error' ||
      pipeline.semantic_extraction === 'error' ||
      pipeline.location_proposal === 'todo' ||
      pipeline.semantic_extraction === 'todo' ||
      pipeline.location_proposal === 'started' ||
      pipeline.semantic_extraction === 'started'
    )
  );

  const startTreeId = useMemo(() => {
    let startTreeId = selectedTreeId;
    if (!startTreeId && todoTrees?.[0]) {
      const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
      const relevantTrees = todoTrees.filter(tree => !skippedTrees.includes(tree.id));
      startTreeId = relevantTrees[0].id;
    }
    return startTreeId;
  }, [selectedTreeId, todoTrees]);
  

  const startAction = {
    isAvailable: !!pipeline && isActionAvailable,
    step: isMicroClimate ? 'validation' : steps[pipeline?.current_manual_step],
    treeId: startTreeId,
  };

  return (
    <Skeleton
      header={{
        headerTitle: isMicroClimate ? 
          title : 
          pipeline?.current_manual_step ? 
            getHeaderTitle(pipeline?.current_manual_step) : 
            title,
        navTabs: generateNavigationTabs(managedArea?.id, managedArea?.code, currentTreeId, handleChangeTree),
      }}
      tabs={[
        {
          title: 'Map',
          icon: 'singapore',
          path: `/${basePath}`,
          exact: true,
        },
        {
          title: 'Managed Area',
          icon: 'map-marked-alt',
          disabled: !managedArea?.id,
          path: `/${basePath}/${managedArea?.id}`
        },
      ]}
    >
      <Switch>
        {screens
          .filter((screen) => !!screen)
          .map((screen) => (
            <Route
              key={screen.path}
              title={screen.title}
              path={screen.path}
              render={(props) => (
                <screen.Component
                  {...{
                    ...props,
                    ...screen.props,
                    trees: sortedTrees?.length ? sortedTrees : trees,
                    todoTrees: todoTrees,
                    managedAreas,
                    pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                    selection,
                    mapRef,
                    layerSources,
                    isToolbarVisible,
                    setIsToolbarVisible,
                    loaded: props.loaded,
                    managedArea,
                    contextsLoadingState,
                    activeTabs,
                    setActiveTabs,
                    setOfficerInCharge,
                    reloadTrees: betaMACtx.fetchTrees,
                    isMicroClimate,
                    selectTree,
                    moveToNeighbour,
                  }}
                />
              )}
            />
          ))}

        <Route
          title={`${managedArea?.code || managedArea?.id || 'Overview'} | ${title}`}
          path={`/${basePath}/:MA?`}
          render={(props) => (
            <ManagedAreaMap
              {...{
                ...props,
                basePath,
                mapRef,
                selection,
                treeStatusFilter,
                customColumns,
                maStatusFilter,
                resetFocus: _handleMAFocus,
                focusOnMA: _handleMapFocus,
                deletedTrees,
                selectedTree,
                setSelectedTree,
                layerFilter,
                layerSources,
                sliders,
                startAction,
                treePopup,
                controls,
                tableHeader,
                proximityAlertTreeIDs,
                setProximityAlertTreeIDs,
                fullHeight: true,
                screenType,
                trees: sortedTrees?.length ? sortedTrees : trees,
                todoTrees,
                pipeline,
                pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                contextsLoadingState,
                managedArea,
                handleTableSortChange,
                handleTableConfigChange,
              }}
            />
          )}
        />
      </Switch>
    </Skeleton>
  );
};

export default SkeletonWithManagedAreaMap;
