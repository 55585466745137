import { useState, useRef, useEffect, useMemo } from 'react';
import TreePopup from '../../components/TreePopup';
import { ScanStatus } from '../../components';
import { useUser } from '../../providers/user';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import OfficerInCharge from './OfficerInCharge';
import { BetaManagedAreasContextProvider, useBetaManagedAreasContext } from '../../hooks/betaHooks/beta-managed-areas-context';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { useRouteMatch } from 'react-router';
import TreeFlowStatus from '../../enums/TreeFlowStatus';

const generateTabs = ({ path, history, hasRole }) => [
  hasRole('supervisor') && {
    title: 'Assign officer in charge',
    icon: 'user-tag',
    active: /officers/.test(path),
    onClick: () => history.push('/overview/officers'),
  },
];

const layerFilter = [];

const layerSources = [
    { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
];

const TaskOverviewRouterComponent = ({ location, history, match }) => {
  const { hasRole, hasAnyRole } = useUser();

  const _handleTab = (path) => history.push(`/overview/${path}`);

  const mapRef = useRef(null);

  const treePopup = ({ feature, ...props }) => {
    requestAnimationFrame(() => {
      const _handleDetails = () => history.push(`/cma/trees/${feature.id}`);

      // TODO: popup handling needs to be refactored, because the button inside the popup
      // won't call onClick method passed by react.
      // ======
      // You won't be able to. At the moment there no way to attach react handlers to mapbox popups.
      // This should handled in the map component, but you won't be able to attach react handlers to the popup.
      // - Balázs
      const button = document.querySelector('#tree-details-handler');
      button.onclick = _handleDetails;
    });
    return <TreePopup tree={feature} {...props} isDetailsVisible />;
  };

  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    betaMASCtx.reset();
    betaMASCtx.fetch();
  }, [managedAreaId]);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;

    betaMACtx.fetch();
  }, [managedAreaId]);

  //useEffect(() => {
  //  if (!currentTreeId) return;

  //  betaTree.reset();
  //  betaTree.fetch();
  //}, [currentTreeId]);

  const [selectedTreeId, setSelectedTreeId] = useState(null);
  const pipeline = betaMACtx?.pipeline;
  const pipelines = betaMASCtx.filteredPipelines?.length ? betaMASCtx.filteredPipelines : betaMASCtx.pipelines;
  const managedArea = betaMACtx?.managedArea;
  const trees = betaMACtx.trees;
  const tree = trees?.find(t => t.id === selectedTreeId) || trees?.[0];

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea?.id]);
  
  const [treeStatusFilter, setTreeStatusFilter] = useState(null);
  const [maStatusFilter, setMAStatusFilter] = useState(null);

  const treeStatistics = managedAreaId
    ? trees.filter(tree => !tree.tree_flow_status?.includes('deleted')).reduce((acc, tree) => {
      if (tree.tree_flow_status === TreeFlowStatus.LocationValidationQueued) acc.pending++;
      else if (tree.tree_flow_status === 'completed') acc.completed++;
      else acc.inprogress++;

      return acc;
    }, { completed: 0, inprogress: 0, pending: 0 })
    : pipelines.reduce((acc, pipeline) => {
          const isCompleted =
            !pipeline.current_manual_step && pipeline.db_match === 'done';

          const isDbMatchInProgress =
            pipeline.current_manual_step === 'db_match';

          const isLocationValidationInProgress =
            pipeline.current_manual_step === 'location_validation';

          if (isCompleted) {
            acc.completed += pipeline.validated || 0;
          } else if (isDbMatchInProgress) {
            acc.completed += pipeline.validated || 0;
            acc.inprogress += pipeline.pending || 0;
          } else if (isLocationValidationInProgress) {
            acc.inprogress += pipeline.validated || 0;
            acc.pending += pipeline.pending || 0;
          } else {
            acc.inprogress +=
              (pipeline.validated || 0) + (pipeline.completed || 0);
          }
          return acc;
        },
        { completed: 0, inprogress: 0, pending: 0 }
      );

  const day = 24 * 60 * 60 * 1000;
  const scan = betaMASCtx.pipelines.reduce((acc, pipeline) => {
    const date = new Date(pipeline.next_inspection).getTime();
    const now = Date.now();

    if (now > date) acc.overdue++;
    else if ((now + 14 * day) > date) acc.weeks++;
    else if ((now + 31 * day) > date) acc.month++;

    return acc;
  }, { overdue: 0, weeks: 0, month: 0 });

  const onStatusFilterChange = (newTreeStatusFilter, newMaStatusFilter) => {
    setTreeStatusFilter(newTreeStatusFilter);
    setMAStatusFilter(newMaStatusFilter);
  }

  return (
    <SkeletonWithManagedAreaMap
      location={location}
      history={history}
      match={match}
      basePath="overview"
      title={'Task Overview'}
      selection={selection}
      trees={trees}
      pipelines={betaMASCtx.filteredPipelines?.length ? betaMASCtx.filteredPipelines : betaMASCtx.pipelines}
      managedAreas={betaMASCtx.filteredManagedAreas?.length ? betaMASCtx.filteredManagedAreas : betaMASCtx.managedAreas}
      pipeline={pipeline}
      managedArea={managedArea}
      onTreeSelection={setSelectedTreeId}
      contextsLoadingState={contextsLoadingState}
      treeId={tree?.id}
      mapRef={mapRef}
      tabs={generateTabs({
        path: location.pathname,
        onPathChange: _handleTab,
        selectedTreeId,
        history,
        hasRole
      })}
      layerFilter={layerFilter}
      layerSources={layerSources}
      treePopup={treePopup}
      tableHeader={ hasAnyRole(['supervisor', 'admin', 'officer']) && <ScanStatus trees={treeStatistics} mas={scan} onFilterChange={onStatusFilterChange}/> }
      screens={[
        hasRole('supervisor') && {
          title: `Officers | Task Overview`,
          path: '/overview/officers',
          Component: OfficerInCharge,
        },
      ]}
      treeStatusFilter={treeStatusFilter}
      maStatusFilter={maStatusFilter}
      setOfficerInCharge={betaMASCtx.setOfficerInCharge}
      isMicroClimate={false}
    />
  );
};

const TaskOverviewRouter = (props) => {
  const match = useRouteMatch();
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  return (
    <BetaManagedAreasContextProvider>
      <BetaManagedAreaContextProvider managedAreaId={managedAreaId}>
        <TaskOverviewRouterComponent {...props}/>
      </BetaManagedAreaContextProvider>
    </BetaManagedAreasContextProvider>
  );
};

export default TaskOverviewRouter;
