import moment from 'moment';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { getProjectYear } from '../../core/projectSelector/projectSelector';
import { useUser } from '../../providers/user';
import { handleRequest } from '../api';

/* copied from internal-validator-frontend/src/components/ScanStatus.js */
export const MA_DEADLINE_STATUS_DATA = {
  overdue: {
    name: 'Overdue',
    value: 'overdue',
  },
  needed_in_2_weeks: {
    name: 'Needed in 2 weeks',
    value: 'needed_in_2_weeks',
  },
  needed_in_1_month: {
    name: 'Needed in 1 month',
    value: 'needed_in_1_month',
  },
} as const;

export const TREE_STATUS_DATA = {
  deleted: {
    name: 'Deleted',
    value: 'deleted',
  },
  completed: {
    name: 'Completed',
    value: 'completed',
  },
  in_progress: {
    name: 'In progress',
    value: 'in_progress',
  },
  pending: {
    name: 'Pending',
    value: 'pending',
  },
} as const;

/* ------------------------------------------------------------------- */

/* I am using this construction instead of enum because enum has some runtime downsides */
// ? You can learn more about this here: https://www.youtube.com/watch?v=jjMbPt_H3RQ

export const FILTERABLE_FIELDS = {
  STATUS: 'status',
  CONFIDENCE_LEVEL: 'confidence_level',
  TMS_CATEGORY: 'tmsCategory',
  PENDING_TREES: 'pending',
} as const;

export const MANAGED_AREA_STATUSES = {
  default: 'all_status',
  LOCATION_VALIDATION: 'location_validation',
  SEGMENTATION_VALIDATION: 'segmentation_validation',
  SEMANTIC_VALIDATION: 'semantic_validation',
} as const;

export type ObjectValues<T> = T[keyof T];

export type FilterableFields = ObjectValues<typeof FILTERABLE_FIELDS>;

export type ManagedAreaStatuses = ObjectValues<typeof MANAGED_AREA_STATUSES>;

export type ManagedAreaDeadLineStatuses = ObjectValues<typeof MA_DEADLINE_STATUS_DATA>;

export type TreeStatus = ObjectValues<typeof TREE_STATUS_DATA>;

export type FilterableField = FilterableFields | ManagedAreaStatuses | ManagedAreaDeadLineStatuses | ManagedAreaDeadLineStatuses;

/* ------------------------------------------------------------------------------- */

export interface SetOfficerInChargeProps {
  managedAreaId: string;
  officerId: string;
  inspectionOfficer: string;
}

export interface BetaManagedAreasContextValue {
  loading: boolean;
  error: any | null;
  managedAreas: any[];
  pipelines: any[];
  maFilters: Record<FilterableFields, string | boolean | null>;
  filteredPipelines: any[];
  filteredManagedAreas: any[];

  updateFilters: (filters: Record<FilterableFields, string | boolean | null>) => Promise<void>;
  filterPipelinesByStatus: (treeStatusFilter: any, maStatusFilter: any) => void;
  fetch: () => Promise<any | undefined>;
  reset: () => Promise<void>;
  setOfficerInCharge: (props: SetOfficerInChargeProps) => Promise<any>;
}

export interface BetaManagedAreasContextProviderProps extends PropsWithChildren {
  isMicroclimate?: boolean;
}

export const BetaManagedAreasContext = createContext<BetaManagedAreasContextValue>(null as any);

export const useBetaManagedAreasContext = () => useContext(BetaManagedAreasContext);

export const BetaManagedAreasContextProvider = (props: BetaManagedAreasContextProviderProps) => {
  const { token, rtmsToken } = useUser() as any;
  const [loading, setLoading] = useState<BetaManagedAreasContextValue['loading']>(false);
  const [error, setError] = useState<BetaManagedAreasContextValue['error']>(null);
  const [pipelines, setPipelines] = useState<BetaManagedAreasContextValue['pipelines']>([]);
  const [managedAreas, setManagedAreas] = useState<BetaManagedAreasContextValue['managedAreas']>([]);
  const [maFilters, setMaFilters] = useState<BetaManagedAreasContextValue['maFilters']>({
    [FILTERABLE_FIELDS.STATUS]: MANAGED_AREA_STATUSES.default,
    [FILTERABLE_FIELDS.CONFIDENCE_LEVEL]: null,
    [FILTERABLE_FIELDS.TMS_CATEGORY]: null,
    [FILTERABLE_FIELDS.PENDING_TREES]: false,
  });
  const [maStatusFilter, setMaStatusFilter] = useState<string | null>(null);
  const [treeStatusFilter, setTreeStatusFilter] = useState<string | null>(null);

  const scanYear = getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : ('latest=1');
  const queryMicroClimateParams = props?.isMicroclimate ? '&isMicroclimate=true' : ''

  const filteredPipelines = useMemo(() => {
    let filteredPipelines: BetaManagedAreasContextValue['filteredPipelines'] = pipelines.filter((pipeline) => {
      return (
        (maFilters.status === MANAGED_AREA_STATUSES.default || maFilters.status === pipeline.current_manual_step) &&
        (!maFilters.pending || Number(pipeline.pending) > 0) &&
        (!props.isMicroclimate || pipeline.microclimate_params_trees_all > 0)
      );
    });

    if (treeStatusFilter || maStatusFilter) {
      const today = moment().startOf('day');

      const newFilteredPipelines = filteredPipelines.filter((pipeline) => {
        let f = true;

        if (treeStatusFilter === TREE_STATUS_DATA.completed.value) {
          if (
            pipeline.location_proposal === 'error' ||
            pipeline.semantic_extraction === 'error' ||
            pipeline.location_proposal === 'todo' ||
            pipeline.semantic_extraction === 'todo' ||
            pipeline.location_proposal === 'started' ||
            pipeline.semantic_extraction === 'started' ||
            pipeline.processing
          ) {
            return false;
          }

          return !pipeline.current_manual_step;
        }

        if (treeStatusFilter) {
          let propName;
          switch (treeStatusFilter) {
            case 'in_progress':
              propName = 'inprogress';
              break;
            default:
              propName = treeStatusFilter;
          }
          f = !!pipeline[propName];
        }

        if (f && maStatusFilter) {
          let minDay;
          let maxDay;
          switch (maStatusFilter) {
            case MA_DEADLINE_STATUS_DATA.overdue.value:
              maxDay = 0;
              break;
            case MA_DEADLINE_STATUS_DATA.needed_in_2_weeks.value:
              minDay = 0;
              maxDay = 14;
              break;
            case MA_DEADLINE_STATUS_DATA.needed_in_1_month.value:
              minDay = 14;
              maxDay = 30;
              break;
            default:
              maxDay = 0;
          }

          const deadline = pipeline['next_inspection'] ? moment(pipeline['next_inspection']).startOf('day') : null;
          const daysDifferenceFromToday = deadline ? deadline.diff(today, 'days') : -1;

          if (
            !(
              (typeof minDay == 'number' && daysDifferenceFromToday <= maxDay && daysDifferenceFromToday > minDay) ||
              (typeof minDay == 'undefined' && daysDifferenceFromToday <= maxDay)
            )
          ) {
            f = false;
          }
        }

        return f;
      });

      return newFilteredPipelines;
    }

    return filteredPipelines;
  }, [pipelines, maFilters.status, maFilters.pending, maStatusFilter, treeStatusFilter]);

  const filteredManagedAreas = useMemo(() => {
    const mas: BetaManagedAreasContextValue['filteredManagedAreas'] = [];

    filteredPipelines?.forEach((pipeline) => {
      const foundManagedArea = managedAreas?.find((managedArea) => managedArea.code === pipeline.managed_area);

      mas.push(foundManagedArea);
    });

    return mas;
  }, [managedAreas, pipelines, filteredPipelines]);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const [pplResponse, maResponse] = await Promise.all([
        handleRequest(token, rtmsToken)(`/v1/pipelines/get?${queryYear}${queryMicroClimateParams}`).then((response) => response.json()),
        handleRequest(
          token,
          rtmsToken
        )(`/v1/query/managed_areas?columns=id,code,frontend_aoi_bbox&${queryYear}`).then((response) => response.json()),
      ]);

      setManagedAreas(maResponse);
      setPipelines(pplResponse);

      return [];
    } catch (e) {
      setError(e as any);
    } finally {
      setLoading(false);
    }
  }, [managedAreas, loading, error, token, rtmsToken, maFilters]);

  const reset = useCallback(async () => {
    setManagedAreas([]);
    setPipelines([]);
    setLoading(false);
    setError(null);
    setMaFilters({
      [FILTERABLE_FIELDS.STATUS]: MANAGED_AREA_STATUSES.default,
      [FILTERABLE_FIELDS.CONFIDENCE_LEVEL]: null,
      [FILTERABLE_FIELDS.TMS_CATEGORY]: null,
      [FILTERABLE_FIELDS.PENDING_TREES]: false,
    });
  }, []);

  const updateFilters = useCallback(
    async (filter: Record<FilterableFields, string | boolean | null>) => {
      setMaFilters((prev) => ({
        ...prev,
        ...filter,
      }));
    },
    [maFilters, setMaFilters, pipelines, managedAreas]
  );

  /* copied from internal-validator-frontend/src/components/ScanStatus.js */
  // and refactored by https://github.com/paterik44
  const filterPipelinesByStatus = useCallback(
    (newTreeStatusFilter: string | null, newMAStatusFilter: string | null) => {
      setTreeStatusFilter(newTreeStatusFilter);
      setMaStatusFilter(newMAStatusFilter);
    },
    [pipelines, filteredPipelines, managedAreas]
  );

  /* ------------------------------------------------------------------- */

  const setOfficerInCharge = useCallback(
    async (props: SetOfficerInChargeProps) => {
      const setOICResponse = await handleRequest(token, rtmsToken)(`/v1/managed_areas/update?id=${props.managedAreaId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          officer_in_charge: props.officerId,
          inspection_officer: props.inspectionOfficer,
        }),
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => response.json());

      if (setOICResponse?.error) {
        return { success: false, error: setOICResponse?.error };
      }

      await fetch();
      return { success: true };
    },
    [fetch, token, rtmsToken]
  );

  const value: BetaManagedAreasContextValue = {
    loading,
    error,
    managedAreas,
    pipelines,
    maFilters,
    filteredPipelines,
    filteredManagedAreas,

    updateFilters,
    filterPipelinesByStatus,
    reset,
    fetch,
    setOfficerInCharge,
  };

  return <BetaManagedAreasContext.Provider value={value}>{props.children}</BetaManagedAreasContext.Provider>;
};
