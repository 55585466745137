import React, { memo, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnType } from '../../@types/Table';
import Tree from '../../@types/Tree';

import TableCell from './Cell';
import NoResult from './NoResult';

import { TableSortOptions } from '../../hooks/betaHooks/beta-use-table-sorter';
import { LoaderWrapper } from '../../layout';
import Row from './Row';
import Title from './Title';

export type TableConfig = {
  columns: ColumnType[];
  selection: (entry: any) => any;
};

const createRowGenerator = (
  config: TableConfig,
  onClick: (tree: Tree, index: any) => void,
  wrapper: RefObject<HTMLDivElement>,
  updateTree: any,
) => (entry: any, index: number) => {
  const columns = config.columns || [];
  const isSelected = (config.selection && config.selection(entry)) || false;

  const _handleClick = () => onClick && onClick(entry, index);

  return (
    <Row
      key={entry.id}
      wrapper={wrapper}
      index={index}
      selected={isSelected}
      onClick={_handleClick}
    >
      {columns.map((column) => (
        <TableCell
          {...column}
          column={column}
          entry={entry}
          key={`${entry.id}-${column.uniqueKey ?? column.key}`}
          updateTree={updateTree}
          selected={isSelected}
        />
      ))}
    </Row>
  );
};

type TableProps = {
  data: any[];
  config: any;
  onClick?: (e: any) => void;
  preventAutoFocus?: boolean;
  updateTree?: boolean;
  contextsLoadingState: boolean;
  tableSort: TableSortOptions;
  setTableSort: React.Dispatch<React.SetStateAction<TableSortOptions | null>>;
  pipeline: any;
  onTableSortChange: (sortOptions: TableSortOptions) => void;
};

const Table = ({
  data,
  config,
  onClick,
  preventAutoFocus,
  updateTree,
  contextsLoadingState,
  onTableSortChange
}: TableProps) => {
  const [tableSort, setTableSort] = useState<TableSortOptions>({ key: 'id', direction: undefined });
  const columns = useMemo(() => config.columns || [], [config.columns]);
  const wrapper = useRef(null);

  useEffect(() => {
    if (!preventAutoFocus) onClick?.(data[0]);
  }, [data, onClick, preventAutoFocus]);

  const tableRows = useMemo(() => {
    const rowGenerator = createRowGenerator(config, onClick!, wrapper, updateTree);
    return data.map(rowGenerator);
  }, [data, config, onClick, updateTree]);

  return (
    <div className={'table-wrapper modern'} ref={wrapper}>
      <LoaderWrapper loading={!!contextsLoadingState}>

        <table cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              {columns.map((column: ColumnType, i: number) => (
                <Title
                  {...column}
                  data={data}
                  onSort={() => {
                    let direction: 1 | -1 | undefined;

                    if (!tableSort?.key || tableSort.key !== column.key) {
                      direction = 1;
                    } else {
                      if (!tableSort?.direction) direction = 1;
                      else if (tableSort?.direction === 1) direction = -1;
                    }
                    const newTableSort = {
                      key: column.key,
                      direction
                    };
                    setTableSort(newTableSort);
                    onTableSortChange(newTableSort);
                  }}
                  sort={tableSort}
                  key={column.key}
                  id={column.key}
                  subTitle={column.subTitle!}
                  subTitleAction={column.subTitleAction!}
                />
              ))}
            </tr>
          </thead>
          <tbody style={{ position: 'relative' }}>
            {tableRows.length > 0 && tableRows}
          </tbody>
        </table>
        {tableRows.length === 0 && <NoResult />}
      </LoaderWrapper>
    </div>
  );
};

export default memo(Table);
