import { memo, MouseEventHandler, PropsWithChildren, RefObject, useEffect } from 'react';

const ROW_HEIGHT = 56;

type RowProps = {
  selected: boolean;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  index: number;
  wrapper: RefObject<HTMLDivElement>;
};

const Row = memo(({ children, selected, onClick, index, wrapper }: PropsWithChildren<RowProps>) => {
  useEffect(() => {
    if (selected && wrapper) {
     // wrapper.current!.scrollTop = Number(index * ROW_HEIGHT);
    }
  }, [index, selected, wrapper]);
  return (
    <tr
      onClick={onClick || (() => {})}
      className={`modern ${selected ? 'selected' : ''} ${onClick ? 'clickable' : ''}`}
      style={{ height: ROW_HEIGHT }}
    >
      {children}
    </tr>
  );
});

export default Row;
