import { useCallback, useMemo } from 'react';
import Tree from '../../@types/Tree';

export interface BetaTreeIndexValue {
  selectTree: (treeId: string) => void;
  moveToNeighbour: (direction?: number) => void;
}

export interface UseBetaTreeIndexProps {
  trees?: Partial<Tree>[];
  skippedTrees?: string[];
  currentTreeId?: string;
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
}

export const useBetaTreeIndex = ({
  trees,
  currentTreeId,
  setTree,
  skippedTrees,
}: UseBetaTreeIndexProps): BetaTreeIndexValue => {
  let currentTreeIndex = useMemo(() => {
    return trees?.findIndex((tree) => tree.id === currentTreeId) ?? -1;
  }, [trees, currentTreeId]);

  let currentTree = useMemo(() => {
    return currentTreeIndex !== -1 ? trees?.[currentTreeIndex] : null;
  }, [trees, currentTreeIndex]);

  const moveToNeighbour = useCallback((direction = 1) => {
    if (!trees || !currentTree) return;

    let relevantTrees = trees;
    if (direction === 1 && skippedTrees) {
      relevantTrees = trees.filter((tree) => !skippedTrees.includes(tree.id!));
    }
    if (relevantTrees.length === 0) return;

    const index = relevantTrees.findIndex((tree) => tree.id === currentTree!.id);
    const nextIndex = index !== -1 ? (index + direction + relevantTrees.length) % relevantTrees.length : 0;
    const treeId = relevantTrees[nextIndex].id;
    setTree(relevantTrees[nextIndex]);
    return { index, nextIndex, treeId };
  }, [currentTree, setTree, skippedTrees, trees]);

  const selectTree = useCallback((treeId: string) => {
    if (!trees) return;

    const index = trees.findIndex((tree) => Number(tree.id) === Number(treeId));
    if (index > -1) {
      setTree(trees[index]);
    }
  }, [setTree, trees]);

  return {
    selectTree,
    moveToNeighbour,
  };
};
