import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab/Tab';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import AreaSelection from '../../@types/AreaSelection';
import Tree from '../../@types/Tree';
import { Finished, ProgressControls } from '../../components';
import ArchiveTreeModal from '../../components/ArchiveTreeModal/ArchiveTreeModal';
import Attributes from '../../components/Icon/Attributes';
import TabPanel from '../../components/Microclimate/Attributes/TabPanel';
import AttributesTab from '../../components/SemanticsValidation/AttributesTab';
import BadSegmentationModal from '../../components/SemanticsValidation/BadSegmentationModal/BadSegmentationModal';
import CanopyCard from '../../components/SemanticsValidation/Cards/CanopyCard';
import CrownHeightCard from '../../components/SemanticsValidation/Cards/CrownHeightCard';
import FirstBifurcationCard from '../../components/SemanticsValidation/Cards/FirstBifurcationCard';
import FirstBifurcationMidpointCard from '../../components/SemanticsValidation/Cards/FirstBifurcationMidpointCard';
import TreeHeightCard from '../../components/SemanticsValidation/Cards/TreeHeightCard';
import { DEFAULT_ELLIPSE } from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants';
import TrunkDetailsCard from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/TrunkDetailsCard';
import ViewGrid from '../../components/SemanticsValidation/Views/ViewGrid';
import ViewWrapper from '../../components/SemanticsValidation/Views/ViewWrapper';
import { Button } from '../../components/inputs';
import IconButton from '../../components/inputs/IconButton';
import { AlertModal } from '../../components/modals';
import { validationActions } from '../../core/progressActions';
import { PointCloudViewType } from '../../enums/PointCloudViewType';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import TreeStatus from '../../enums/TreeStatus';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { BetaTreeContextValue, CommentTypes, useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { SemanticScreenContextProvider, useSemanticScreenContext } from '../../hooks/betaHooks/semantic-screen-context';
import { default as LoaderWrapper } from '../../layout/Loader';
import HOTKEYS from '../../providers/hotkeys';
import { useModal } from '../../providers/modal';

export const getDefaultGirth = () => {
  return {
    local_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
    ...DEFAULT_ELLIPSE,
  };
};

const attributesTabIndex = 0;

const a11yProps = (name: string, index: number) => {
  return {
    id: `${name}-tab-${index}`,
    'aria-controls': `${name}-tabpanel-${index}`,
  };
};

interface Props {
  currentTree: Partial<Tree> | null;
  trees: Tree[];
  todoTrees: Tree[];
  loaded: boolean;
  pendingUpdateTree: boolean;
  selection: AreaSelection[];
  contextsLoadingState: boolean;
  treeId: string;
  managedArea: any;
  reloadTrees: () => Promise<any[] | undefined>;
  moveToNeighbour: (direction?: number) => void;
  location: { pathname: string };
  betaTree: BetaTreeContextValue;
}

const SemanticValidation = ({
  currentTree,
  selection,
  pendingUpdateTree,
  contextsLoadingState,
  trees,
  todoTrees,
  managedArea,
  moveToNeighbour,
  betaTree
}: Props) => {
  const {
    loading: btLoading,
    fetch: reloadTree,
    updateTree,
    syncTrunk,
    addComment,
    removeComment,
    updateTmsCategory,
    setTree,
  } = betaTree;

  const { pointCloudModule, cards, validation, actions, helper, treeMetrics } = useSemanticScreenContext();

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const muiTheme = useMuiTheme();

  const [finished, setFinished] = useState(false);
  const [isMaFinished, setIsMaFinished] = useState(false);

  useEffect(() => {
    if (!currentTree?.id || currentTree?.location) return;

    setTree({
      ...currentTree,
      location: JSON.parse(currentTree?.location as any),
    });
  }, [currentTree?.id, currentTree?.location]);

  const isLoading = () => {
    return (!pointCloudModule.pointCloud?.geometry || !currentTree?.location || btLoading || contextsLoadingState) && !isMaFinished;
  };

  const handleComplete = useCallback(async (tree: Tree) => {
    if (!tree) return;

    const completeStatus = { status: TreeStatus.SemanticValidationDone, tree_flow_status: TreeFlowStatus.MeasurementValidationDone };
    const data = helper.convertMetricsToDb({ ...tree, ...completeStatus });
    for (const trunk of (data.trunks ?? [])) {
      await syncTrunk(trunk);
    }

    delete data.trunks;
    await updateTree(data);
    await betaManagedAreaContext.fetchTrees();
    await reloadTree();
  }, [betaManagedAreaContext, helper, reloadTree, syncTrunk, updateTree]);

  const _handleComment = async (comment: { message: string; comment_type: CommentTypes }) => {
    await addComment({
      message: comment.message,
      comment_type: comment.comment_type,
      isUndoAction: false,
    });
    await reloadTree();
  };

  const _removeComment = async (commentId: string) => {
    await removeComment(commentId);
    await reloadTree();
  };

  const _handleTSEJob = () => {
    betaManagedAreaContext.startPostValidation(selection[0], 'semantic_validation');
    setFinished(true);
  };
  const [badSegmentationModalIsOpen, setBadSegmentationModalIsOpen] = useState(false);
  const [archiveTreeModalIsOpen, setArchiveTreeModalIsOpen] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  useHotkeys(
    HOTKEYS.semantics.MARK_ATTRIBUTE_COMPLETE,
    () => {
      helper.activeTool && actions.setActionCompleted(helper.activeTool);
    },
    [helper.activeTool]
  );

  const setTreeStatus = useCallback(async () => {
    if (!currentTree?.id) return;

    if (currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone) {
      await updateTree({
        status: TreeStatus.SemanticValidationTodo,
        tree_flow_status: TreeFlowStatus.MeasurementValidationQueued
      });
    } else {
      await handleComplete(currentTree as Tree);

      if (todoTrees.length === 0) {
        setIsMaFinished(true);
      } else {
        moveToNeighbour();
      }
    }
  }, [currentTree, handleComplete, moveToNeighbour, todoTrees.length, updateTree]);

  const handleCompleteClick = useCallback(() => {
    if (cards.alerts.length > 0) {
      setAlertModalIsOpen(true);
      return;
    }

    setTreeStatus();
  }, [cards.alerts.length, setTreeStatus]);

  const renderMemoizedButton = useMemo(() => {
    return currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone ? (
      <Button
        label={'Resume Editing'}
        onClick={setTreeStatus}
        style={{ backgroundColor: '#6B6B6B', padding: '4px 29px', width: 'fit-content' }}
        leadingIcon={'resume'}
      />
    ) : validation.areAttributesCompleted && !!currentTree?.tms_category ? (
      <Button
        label={'Mark Complete'}
        onClick={handleCompleteClick}
        style={{ backgroundColor: '#058B62', padding: '4px 29px', width: 'fit-content' }}
        leadingIcon={'checkmark'}
      />
    ) : (
      <Button
        label={'Incomplete'}
        onClick={() => { }}
        style={{ backgroundColor: '#DB200B', padding: '4px 29px', width: 'fit-content', cursor: 'not-allowed' }}
        leadingIcon={'exclamation'}
      />
    );
  }, [
    validation.areAttributesCompleted,
    setTreeStatus,
    currentTree?.tree_flow_status,
    currentTree?.tms_category,
    handleCompleteClick
  ]);

  // Getting modal methods
  const { presentModal, dismissModal } = useModal();

  const handleCompletedSemanticTrees = useCallback(async () => {
    if (todoTrees.length > 0) return null;

    await betaManagedAreaContext.moveTreesFromSemanticToMicroclimate(managedArea?.code)
  }, [todoTrees.length, betaManagedAreaContext, managedArea?.code]);

  return (
    <LoaderWrapper loading={!!pendingUpdateTree || !currentTree?.id || isLoading()}>
      <Finished
        finished={todoTrees.length === 0}
        onStart={_handleTSEJob}
        done={finished}
        managedAreaId={managedArea?.id}
        reloadManagedAreas={betaManagedAreaContext.fetch}
        handleCompletedSemanticTrees={handleCompletedSemanticTrees}
      >
        {Boolean(cards.toasts.length) && (
          <div className='toast-wrapper left'>
            {cards.toasts.map((toast, index) => (
              <div key={index} className={`toast ${toast.type} ${cards.toasts.length - 1 === index ? 'last' : ''}`}>
                {toast.message}
              </div>
            ))}
          </div>
        )}
        <div className='semantics-editor-wrapper validation-phase'>
          <div className='validation-layout'>
            <div className='viewers'>
              <ViewGrid layout='vertical-right-halved'>
                {pointCloudModule.pointCloud && (
                  <Fragment>
                    <ViewWrapper
                      initialView={PointCloudViewType.PERSPECTIVE}
                      currentTree={currentTree}
                    />
                    <ViewWrapper
                      initialView={PointCloudViewType.TOP_DOWN}
                      currentTree={currentTree}
                    />
                    <ViewWrapper
                      initialView={PointCloudViewType.SECTION}
                      currentTree={currentTree}
                    />
                  </Fragment>
                )}
              </ViewGrid>
            </div>
            <div className='semantic-progress-controls'>
              <ProgressControls
                max={trees.length}
                value={trees.length - todoTrees.length}
                hideProgressBar={false}
                disabled={false}
                actions={validationActions({
                  onUndo: undefined,
                  isUndoAvailable: false,
                  tree: currentTree,
                  updateTree: async (status: TreeStatus, tree_flow_status: TreeFlowStatus) => {
                    await updateTree({ status, tree_flow_status });
                    await betaManagedAreaContext.fetchTrees();
                    moveToNeighbour(1);
                  },
                  showTodoAction: false,
                  commentTree: undefined,
                  removeTreeComment: undefined,
                  removeTree: undefined,
                  sendToField: () => alert('Unimplemented feature'),
                  onSkip: undefined,
                  onRotation: undefined,
                  numberOfTrees: todoTrees.length,
                  validationTool: undefined,
                  changeTool: undefined,
                  dismissModal,
                  presentModal,
                  showComment: false,
                  isSemantics: false,
                  showDeleteConfirmation: true,
                  deleteHidden: true,
                  saveDisabled: false,
                  disabled: false,
                  tmsCategory: treeMetrics.tmsCategory,
                  onSetTmsCategory: async (category: any) => {
                    if (category === 'l3') {
                      await updateTree({
                        tms_category: 'l3',
                        status: TreeStatus.SentToField,
                        tree_flow_status: TreeFlowStatus.SentToField,
                      });
                      moveToNeighbour();
                    } else {
                      const success = await updateTmsCategory(category);
                      if (success) {
                        treeMetrics.setTmsCategory(category);
                      }
                    }
                  },
                  showTmsButtons: true,
                  showTmsL3Button: true,
                  showNavigationArrows: false,
                })}
              />
            </div>
          </div>

          <div className='tight-side-wrapper'>
            <div className={`attributes-wrapper`} style={{ backgroundColor: '#202324' }}>
              <div className={`attributes-container`} style={{ backgroundColor: '#202324' }}>
                <div className='tabs-wrapper'>
                  <AttributesTab value={attributesTabIndex} aria-label='microclimate attribute tabs' variant='fullWidth'>
                    <Tab
                      icon={<Attributes color={muiTheme.palette.text.primary} />}
                      disableRipple
                      sx={{ cursor: 'default' }}
                      iconPosition='start'
                      label={
                        <span className={'title'} style={{ color: muiTheme.palette.text.primary }}>
                          Attributes
                        </span>
                      }
                      {...a11yProps('attributes', 0)}
                    />
                  </AttributesTab>
                </div>
                <TabPanel value={0} index={0} name='attributes' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                  <div className='attributes-panel' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <TreeHeightCard />
                    <CrownHeightCard />
                    <FirstBifurcationCard currentTree={currentTree} />
                    <FirstBifurcationMidpointCard currentTree={currentTree} />
                    <CanopyCard />
                    {/* <LeanAngleCard disabled={currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationDone} /> */}
                    <TrunkDetailsCard currentTree={currentTree} />
                    <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
                      <Button
                        label={'Approve all'}
                        onClick={validation.handleApproveAllActions}
                        leadingIcon={'approve'}
                        style={{
                          color: '#818385',
                          padding: '4px 14px',
                          backgroundColor: 'transparent',
                          border: 'solid 1px #818385',
                          margin: 0,
                        }}
                      />
                      <Button
                        label={'Deselect all'}
                        onClick={validation.handleDeselectAllActions}
                        leadingIcon={'deselect'}
                        style={{
                          color: '#818385',
                          padding: '4px 14px',
                          backgroundColor: 'transparent',
                          border: 'solid 1px #818385',
                          margin: 0,
                        }}
                      />
                    </div>
                    <div className='helperText'>
                      {!validation.areAttributesCompleted && <p>You have to complete all Attributes!</p>}
                      {!currentTree?.tms_category && <p>You have to set tree's TMS Category!</p>}
                    </div>
                  </div>
                </TabPanel>
              </div>
              <div
                className={'attributes-actions-wrapper'}
                style={{
                  display: 'flex',
                  padding: '12px 36px',
                  gap: '20px',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                  borderTop: 'solid 1px #6B6B6B',
                }}
              >
                {cards.alerts?.length > 0 && (
                  <Tooltip title='There are some suspicious attributes'>
                    <WarningAmberOutlinedIcon color='warning' />
                  </Tooltip>
                )}
                {renderMemoizedButton}
                <IconButton
                  icon={'bad-segmentation'}
                  onClick={() => setBadSegmentationModalIsOpen(true)}
                  tooltip='Send to correction'
                  tooltipDirection='top'
                />
                <IconButton
                  icon={'archive'}
                  style={{ color: '#E8220C' }}
                  onClick={() => setArchiveTreeModalIsOpen(true)}
                  tooltip='Delete tree'
                  tooltipDirection='top'
                />
              </div>
            </div>
          </div>

          {badSegmentationModalIsOpen && (
            <BadSegmentationModal
              currentTree={currentTree}
              setVisible={setBadSegmentationModalIsOpen}
              onSave={async (changes: { status: TreeStatus, tree_flow_status: TreeFlowStatus }) => {
                await updateTree(changes);
                await betaManagedAreaContext.fetch();
                moveToNeighbour(1);
              }}
              handleComment={_handleComment}
              removeComment={_removeComment}
            />
          )}
          <AlertModal
            isOpen={alertModalIsOpen}
            handleClose={() => setAlertModalIsOpen(false)}
            handleCompleteClick={setTreeStatus}
          />
          <ArchiveTreeModal
            visible={archiveTreeModalIsOpen}
            setVisible={setArchiveTreeModalIsOpen}
            handleArchive={async () => {
              await updateTree({
                status: TreeStatus.SemanticValidationDeleted,
                tree_flow_status: TreeFlowStatus.MeasurementValidationDeleted,
              });
              await betaManagedAreaContext.fetch();
              moveToNeighbour(1);
              setArchiveTreeModalIsOpen(false);
            }}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  );
};

const ContextWrappedSemanticValidation = (props: Props) => {
  const urlParams = useParams<{ tree: string }>();
  const betaTree = useBetaTree({
    treeId: urlParams.tree,
    managedAreaId: props.managedArea?.id,
    managedAreaCode: props.managedArea?.code,
  });
  const { tree: currentTree } = betaTree;

  return (
    <SemanticScreenContextProvider currentTree={currentTree}>
      <SemanticValidation {...{ ...props, currentTree, betaTree }} />
    </SemanticScreenContextProvider>
  );
}

export default ContextWrappedSemanticValidation;
