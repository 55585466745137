import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { getProjectYear } from '../../core/projectSelector/projectSelector';
import { useUser } from '../../providers/user';
import { handleRequest } from '../api';

export interface BetaManagedAreaContextValue {
  loadingTrees: boolean;
  loadingManagedAreaAndPipeline: boolean;
  loading: boolean;

  errorTrees: any | null;
  errorManagedAreaAndPipeline: any | null;
  error: any | null;

  trees: any[];
  managedArea: any | null;
  pipeline: any | null;

  fetchTrees: () => Promise<any[] | undefined>;
  fetchManagedAreaAndPipeline: () => Promise<{ managedArea: any | undefined; pipeline: any | undefined } | undefined>;
  fetch: () => Promise<{ trees: any[]; managedArea: any | undefined; pipeline: any | undefined } | undefined>;
  reset: () => Promise<void>;

  startPostValidation: ({ code, id }: { code: any; id: any }, step: string) => Promise<void>;
  moveTreesFromSemanticToMicroclimate: (managedAreaCode: string) => Promise<any>;
}

export interface BetaManagedAreaContextProviderProps extends PropsWithChildren {
  managedAreaId: number;
  isMicroclimate?: boolean;
}

export const BetaManagedAreaContext = createContext<BetaManagedAreaContextValue>(null as any);

export const useBetaManagedAreaContext = () => useContext(BetaManagedAreaContext);

export const BetaManagedAreaContextProvider = ({
  children,
  managedAreaId,
  isMicroclimate = false,
}: BetaManagedAreaContextProviderProps) => {
  const { token, rtmsToken } = useUser() as any;

  const [loadingTrees, setLoadingTrees] = useState<BetaManagedAreaContextValue['loadingTrees']>(false);
  const [loadingManagedAreaAndPipeline, setLoadingManagedAreaAndPipeline] =
    useState<BetaManagedAreaContextValue['loadingManagedAreaAndPipeline']>(false);
  const loading = useMemo<BetaManagedAreaContextValue['loading']>(
    () => loadingTrees || loadingManagedAreaAndPipeline,
    [loadingTrees, loadingManagedAreaAndPipeline]
  );

  const [errorTrees, setErrorTrees] = useState<BetaManagedAreaContextValue['errorTrees']>(false);
  const [errorManagedAreaAndPipeline, setErrorManagedAreaAndPipeline] =
    useState<BetaManagedAreaContextValue['errorManagedAreaAndPipeline']>(false);
  const error = useMemo<BetaManagedAreaContextValue['error']>(
    () => errorTrees || errorManagedAreaAndPipeline,
    [errorTrees, errorManagedAreaAndPipeline]
  );

  const [trees, setTrees] = useState<BetaManagedAreaContextValue['trees']>([]);
  const [managedArea, setManagedArea] = useState<BetaManagedAreaContextValue['managedArea']>(null);
  const [pipeline, setPipeline] = useState<BetaManagedAreaContextValue['pipeline']>(null);

  const scanYear = getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : 'latest=1';

  const reset = useCallback(async () => {
    setLoadingTrees(false);
    setLoadingManagedAreaAndPipeline(false);
    setErrorTrees(null);
    setErrorManagedAreaAndPipeline(null);
    setTrees([]);
    setManagedArea(null);
    setPipeline(null);
  }, []);

  const fetchTrees = useCallback(async () => {
    if (!managedAreaId) {
      console.warn('BetaManagedAreaContextProvider has not ManagedAreaId defined');
      return;
    }

    try {
      setLoadingTrees(true);
      setErrorTrees(null);

      const treesResponse = await handleRequest(
        token,
        rtmsToken
      )(`v1/trees?mas=${managedAreaId}&pi=true&${queryYear}&q=${new Date().getTime()}${isMicroclimate ? `&vs=microclimate` : ''}`).then(
        (response) => response.json()
      );

      setTrees(treesResponse);

      return treesResponse;
    } catch (e) {
      setErrorTrees(e as any);
    } finally {
      setLoadingTrees(false);
    }
  }, [trees, loadingTrees, errorTrees, managedAreaId]);

  const fetchManagedAreaAndPipeline = useCallback(async () => {
    if (!managedAreaId) {
      console.warn('BetaManagedAreaContextProvider has not ManagedAreaId defined');
      return;
    }

    try {
      setLoadingManagedAreaAndPipeline(true);
      setErrorManagedAreaAndPipeline(null);

      const managedAreaResponse = await handleRequest(
        token,
        rtmsToken
      )(`/v1/query/managed_areas?eq_column=id&eq_value=${managedAreaId}&${queryYear}`).then((response) => response.json());

      if (!managedAreaResponse?.[0]) {
        throw new Error('ManagedAreaNotFound');
      }

      const pipelineResponse = await handleRequest(token, rtmsToken)(
        `/v1/pipelines/get?managedAreaCode=${managedAreaResponse[0].code}&${queryYear}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }
      ).then((response) => response.json());

      if (!pipelineResponse?.[0]) {
        throw new Error('PipelineNotFound');
      }

      setManagedArea(managedAreaResponse[0]);
      setPipeline(pipelineResponse[0]);

      return {
        managedArea: managedAreaResponse[0],
        pipeline: pipelineResponse[0],
      };
    } catch (e) {
      setErrorManagedAreaAndPipeline(e as any);
    } finally {
      setLoadingManagedAreaAndPipeline(false);
    }
  }, [managedArea?.id, loadingManagedAreaAndPipeline, errorManagedAreaAndPipeline, managedAreaId]);

  const fetch = async () => {
    const [trees, combined] = await Promise.all([fetchTrees(), fetchManagedAreaAndPipeline()]);

    combined?.managedArea?.id && localStorage.setItem('managedAreaId', combined?.managedArea?.id);

    return {
      trees,
      managedArea: combined?.managedArea,
      pipeline: combined?.pipeline,
    };
  };

  const startPostValidation = useCallback(
    async ({ code, id }: { code: any; id: any }, step: any) => {
      await handleRequest(token, rtmsToken)(
        `/v1/pipelines/start-post-validation?done_step=${step}&managed_area=${code}&managed_area_id=${id}&scan_interval=${value.pipeline?.scan_interval}`,
        { method: 'POST' }
      );
    },
    [pipeline, rtmsToken, token]
  );

  const moveTreesFromSemanticToMicroclimate = useCallback(async (managedAreaCode: string) => {
    await handleRequest(token, rtmsToken)(
      `/v1/trees/semantic-to-ecosystem`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          managedAreaCode,
        }),
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }, [token, rtmsToken]);

  const value: BetaManagedAreaContextValue = {
    loadingTrees,
    loadingManagedAreaAndPipeline,
    loading,

    errorTrees,
    errorManagedAreaAndPipeline,
    error,

    trees,
    managedArea,
    pipeline,

    fetchTrees,
    fetchManagedAreaAndPipeline,
    fetch,

    reset,
    startPostValidation,
    moveTreesFromSemanticToMicroclimate,
  };

  return <BetaManagedAreaContext.Provider value={value}>{children}</BetaManagedAreaContext.Provider>;
};
