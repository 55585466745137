import { useCallback, useMemo, useState } from 'react';
import { ColumnType } from '../../@types/Table';
import { tableSorter } from '../../components/Table/helpers';
import { TableConfig } from '../../components/Table/Table';

export interface TableSortOptions {
  key: string;
  direction: 1 | -1 | undefined;
}

export interface BetaTableSorterProps {
  data: any[];
}

export interface BetaTableSorterValue {
  sortedData: any[];
  handleTableSortChange: (sortOptions: TableSortOptions) => void;
  handleTableConfigChange: (config: TableConfig) => void;
}

const useBetaTableSorter = ({ data }: BetaTableSorterProps): BetaTableSorterValue => {
  const [sortOptions, setSortOptions] = useState<TableSortOptions>();
  const [config, setConfig] = useState<TableConfig>();

  const handleTableSortChange = useCallback((sortOptions: TableSortOptions) => {
    setSortOptions(sortOptions);
  }, []);

  const handleTableConfigChange = useCallback((config: TableConfig) => {
    setConfig(config);
  }, []);

  const sortedData = useMemo(() => {
    if (!config || !sortOptions) return [];

    const sortKey = sortOptions.key;
    const sortDir = sortOptions.direction;
    const column = config.columns.find((column: ColumnType) => column.key === sortKey);
    return tableSorter(data, sortDir, column!, Object.byResolver);
  }, [data, config, sortOptions])

  return {
    sortedData,
    handleTableSortChange,
    handleTableConfigChange
  };
};

export default useBetaTableSorter;
